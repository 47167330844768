// Use direct JSON imports for build-time bundling
import enUS from "dictionaries/en-US.json";
import jaJP from "dictionaries/ja-JP.json";
import koKR from "dictionaries/ko-KR.json";
import zhCN from "dictionaries/zh-CN.json";
import zhTW from "dictionaries/zh-TW.json";
import esMX from "dictionaries/es-MX.json";
import deDE from "dictionaries/de-DE.json";
import frCA from "dictionaries/fr-CA.json";
import ruRU from "dictionaries/ru-RU.json";
import ptBR from "dictionaries/pt-BR.json";

export const locales = [
  "en-US",
  "ja-JP",
  "ko-KR",
  "zh-CN",
  "zh-TW",
  "es-MX",
  "de-DE",
  "fr-CA",
  "ru-RU",
  "pt-BR",
] as const;

export type ValidLocale = (typeof locales)[number];

export const indexToLocaleKey: Record<number, string> = {
  0: "en-US",
  1: "ja-JP",
  2: "ko-KR",
  3: "zh-CN",
  4: "zh-TW",
  5: "es-MX",
  6: "de-DE",
  7: "fr-CA",
  8: "ru-RU",
  9: "pt-BR",
};

// Static dictionary map with O(1) lookup
const DICTIONARIES: Record<ValidLocale, Record<string, any>> = {
  "en-US": enUS,
  "ja-JP": jaJP,
  "ko-KR": koKR,
  "zh-CN": zhCN,
  "zh-TW": zhTW,
  "es-MX": esMX,
  "de-DE": deDE,
  "fr-CA": frCA,
  "ru-RU": ruRU,
  "pt-BR": ptBR,
};

// O(1) synchronous lookup function - no async needed
export async function loadTranslation(locale: ValidLocale): Promise<Record<string, any>> {
  // Simple O(1) lookup from the static map
  return DICTIONARIES[locale];
}

// For generateStaticParams - exclude en-US since it's the default route
export const STATIC_LOCALE_PARTS = locales
  .filter((locale) => locale !== "en-US")
  .map((locale) => {
    const [lang, country] = locale.split("-");
    return { lang, country: country.toLowerCase() };
  });

type PathnameLocale = {
  pathname: string;
  locale?: never;
};

type ISOLocale = {
  pathname?: never;
  locale: string;
};

type LocaleSource = PathnameLocale | ISOLocale;

export const getLocalePartsFrom = ({ pathname, locale }: LocaleSource) => {
  if (locale) {
    const localeParts = locale.toLowerCase().split("-");
    return {
      lang: localeParts[0],
      country: localeParts[1],
    };
  } else {
    const pathnameParts = pathname!.toLowerCase().split("/");
    return {
      lang: pathnameParts[1],
      country: pathnameParts[2],
    };
  }
};

export const getLocalePath = (lang: string, country: string) => {
  return lang + "-" + country === "en-US" ? "" : `/${lang}/${country}`;
};

// Legacy translator
// export const getTranslator = async (locale: ValidLocale) => {
//   const dictionary = await dictionaries[locale]();
//   return (key: string, params?: { [key: string]: string | number }) => {
//     let translation = key.split(".").reduce((obj, key) => obj && obj[key], dictionary);
//     if (!translation) {
//       return key;
//     }
//     if (params && Object.entries(params).length) {
//       Object.entries(params).forEach(([key, value]) => {
//         translation = translation!.replace(`{{ ${key} }}`, String(value));
//       });
//     }
//     return translation;
//   };
// };
